import React, { useEffect, useState } from "react";
import MinerAccordion from "./MinerAccordion";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { Spinner } from "reactstrap";

export default function MinerDets({ updateParentValue }) {
  let navigate = useNavigate();
  const { account, isActive, connector } = useWeb3React();
  const [summaryData, setSummaryData] = useState({ data: null, loading: true });
  const [activeData, setActiveData] = useState({ data: [] });
  const [InactiveData, setInActiveData] = useState({ data: [] });
  const [currentData, setcurrentData] = useState({ data: [], loading: true });
  const [currentSel, setCurrentsel] = useState("all");
  const [currentSelType, setcurrentSelType] = useState("inactive");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getSummary();
  }, []);

  const getSummary = async () => {
    const url =
      "https://egold-treasury.tagdev.info/v1/summary/minersOf/" +
      localStorage.getItem("acct");

    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(async function (newData) {
        if (newData["detail"] == undefined) {
          if (newData.length > 0) {
            console.log("newData", newData);
            setInActiveData({ data: newData });
            setcurrentData({ data: newData, loading: false });
            setcurrentSelType("inactive");
          } else {
            setcurrentData({ data: [], loading: false });
          }
          const urlactive =
            "https://egold-miner.tagdev.info/miner/" +
            localStorage.getItem("acct");
          fetch(urlactive)
            .then(function (response) {
              return response.json();
            })
            .then(async function (activeData) {
              if ("detail" in activeData) {
                mergeMiners([], newData);
              } else {
                if (activeData.length > 0) {
                  setActiveData({ data: activeData });
                }
                mergeMiners(activeData, newData);
              }
            })
            .catch(function (error) {
              console.log("Requestfailed", error);
            });
        } else {
          console.log("hereeee");
          setcurrentData({ data: [], loading: false });
        }
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
  };

  function mergeMiners(active, inactive) {
    const mergedArray = [...active, ...inactive];
    setSummaryData({ data: mergedArray, loading: false });
    updateParentValue(mergedArray.length);
  }

  function changeView(type) {
    setcurrentData({ data: [], loading: true });
    setcurrentSelType(type);
    if (type == "inactive") {
      setcurrentData({ data: InactiveData.data, loading: false });
    } else {
      setcurrentData({ data: activeData.data, loading: false });
    }
  }
  return (
    <>
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation" style={{ width: "50%" }}>
          <button
            class="nav-link typetabbtn  brltb active "
            id="pills-gseries-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-gseries"
            type="button"
            role="tab"
            aria-controls="pills-gseries"
            aria-selected="false"
            onClick={() => {
              changeView("inactive");
            }}
          >
            UNUSED
          </button>
        </li>
        <li class="nav-item" role="presentation" style={{ width: "50%" }}>
          <button
            class="nav-link typetabbtn brrtb"
            id="pills-sseries-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-sseries"
            type="button"
            role="tab"
            aria-controls="pills-sseries"
            aria-selected="true"
            onClick={() => {
              changeView("active");
            }}
          >
            USED
          </button>
        </li>
      </ul>
      <select
        class="form-select text-white mb20 darkbgselect"
        aria-label="Default select example"
        onChange={(e) => {
          setCurrentsel(e.target.value);
        }}
        style={{ borderRadius: "8px", backgroundColor: "#648795" }}
      >
        <option value="all">All</option>
        <option value="S">S Series</option>
        <option value="G">G Series</option>
        <option value="H">H Series</option>
        <option value="X">X Series</option>
      </select>
      {summaryData.loading == true || currentData.loading == true ? (
        <div style={{ textAlign: "center" }}>
          <Spinner />
        </div>
      ) : currentData.data.length > 0 ? (
        <div class="mineraccordian">
          <div class="accordion" id="accordionExample">
            {currentData.data !== null
              ? currentData.data
                .slice()
                .sort((a, b) => b._id - a._id)
                .map((item) => {
                  return (
                    <MinerAccordion
                      id={item._id}
                      status={currentSelType}
                      selected={currentSel}
                    />
                  );
                })
              : ""}
          </div>
        </div>
      ) : (
        <p style={{ textAlign: "center" }}>No miner data available</p>
      )}
    </>
  );
}
