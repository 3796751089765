import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";

export default function InvalidReferCard(props) {
  let navigate = useNavigate();
  const { account, isActive, connector } = useWeb3React();
  return (
    <div>
      <div
        class="walletamtsec mb20"
        style={{
          background: "white",
          padding: "25px 0px",
          borderRadius: "8px",
        }}
      >
        <div style={{ textAlign: "center", paddingBottom: "18px" }}>
          <p style={{ color: "black" }}>
            You don't own any miners at the moment
          </p>
        </div>
        <div class="secpadding brblr" style={{ padding: "10px" }}>
          <button
            className="btn-color-primary"
            onClick={() => {
              navigate("/buy");
            }}
            style={{ margin: "auto", width: "200px", border: "0px" }}
          >
            Explore Miners
          </button>
        </div>
      </div>
    </div>
  );
}
