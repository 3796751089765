import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModalBody, Modal, ModalHeader } from "reactstrap";
import { useWeb3React } from "@web3-react/core";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Spinner } from "reactstrap";
import dataVal from "../../../data/Abis.json";
import LoaderCard from "../../LoaderCard/LoaderCard";
import ConfirmCard from "../../LoaderCard/ConfirmCard";
import ErrorCard from "../../LoaderCard/ErrorCard";

export default function purchaseButton(props) {
  let navigate = useNavigate();

  const { account, isActive, connector } = useWeb3React();

  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);

  let miner_type = props.miner;

  const [refAddress, setRefAddress] = useState("...");
  const [showbnberrorModal, setshowbnberrorModal] = useState(false);
  const [showtokerrorModal, setshowtokerrorModal] = useState(false);
  const [tokenapp, setTokenapp] = useState(true); //token approve button enabled
  const [loadapprove, setLoadapprove] = useState(false); //approve button loader
  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [valid, setisValid] = useState("...");

  const handleClosebnberr = () => setshowbnberrorModal(false);
  const handleClosetokerr = () => setshowtokerrorModal(false);

  const identitycontractInstance = new web3.eth.Contract(
    dataVal.identityabi,
    process.env.REACT_APP_INDENTITY_ADDR
  );

  useEffect(() => {
    checkRef();

    if (localStorage.getItem("ref")) {
      setRefAddress(localStorage.getItem("ref"));
    }
  }, []);

  async function checkRef() {
    var wAddress = localStorage.getItem("acct");

    if (wAddress) {
      if (
        wAddress.toLowerCase() ==
        process.env.REACT_APP_MASTER_PARENT.toLowerCase()
      ) {
        setisValid(true);
      } else {
        await identitycontractInstance.methods
          .fetchUser(localStorage.getItem("acct"))
          .call()
          .then((value) => {
            if (value.parent == "0x0000000000000000000000000000000000000000") {
              setisValid(false);
            } else {
              setisValid(true);
            }
          })
          .catch((error) => console.error(error));
      }
    }
    return 1;
  }

  //to check if sufficient token balance is available
  async function checkBalance() {
    var validref = false;
    setprocessState({
      state: "...",
      data: null,
    });
    if (!valid) {
      if (!web3.utils.isAddress(refAddress)) {
        setprocessState({
          state: "error",
          data: "Invalid Referral Address",
        });
        validref = false;
      } else
        await identitycontractInstance.methods
          .fetchUser(refAddress)
          .call()
          .then((value) => {
            if (value.parent == "0x0000000000000000000000000000000000000000") {
              setprocessState({
                state: "error",
                data: "Invalid Referral Address",
              });
              validref = false;
            } else {
              validref = true;
            }
          })
          .catch((error) => console.error(error));
    }
    if (valid || validref) {
      var xbal = await web3.eth.getBalance(account);
      if (parseFloat(web3.utils.fromWei(xbal, "ether")).toFixed(4) < 0.005) {
        setshowbnberrorModal(true);
        return false;
      } else {
        if (props.selected == "egoldv1") {
          var tokenaddr = process.env.REACT_APP_EGOLD_ADDR;
        } else if (props.selected == "egoldv2") {
          var tokenaddr = process.env.REACT_APP_EGOLDV2_ADDR;
        } else if (props.selected == "busd") {
          var tokenaddr = process.env.REACT_APP_BUSD_ADDR;
        } else if (props.selected == "wbnb") {
          var tokenaddr = process.env.REACT_APP_WBNB_ADDR;
        } else if (props.selected == "cbk") {
          var tokenaddr = process.env.REACT_APP_CBK_ADDR;
        }
        const tokcontractInstance = new web3.eth.Contract(
          dataVal.tokenabi,
          tokenaddr
        );
        var x = await tokcontractInstance.methods
          .balanceOf(account)
          .call()
          .then((value) => {
            if (
              parseFloat(web3.utils.fromWei(value, "ether")) <
              parseFloat(props.tokprice)
            ) {
              setshowtokerrorModal(true);
              return false;
            } else {
              return true;
            }
          })
          .catch((error) => console.error(error));
        return x;
      }
    }
  }

  //To approve WBNB
  async function approveWBNB() {
    var x = await checkBalance();
    if (x) {
      try {
        setLoadapprove(true);
        const webb3 = new Web3(connector.provider);

        const web3 = new Web3(process.env.REACT_APP_RPC);
        const contractInstance = new web3.eth.Contract(
          dataVal.mregistryabi,
          process.env.REACT_APP_MINER_REGISTRY
        );

        const routerInstance = new web3.eth.Contract(
          dataVal.pcrouterabi,
          process.env.REACT_APP_PANCAKE_ROUTER_ADDR
        );

        await contractInstance.methods
          .fetchMinerInfo(miner_type)
          .call()
          .then(async (res) => {
            var uprate =
              parseFloat(res.minerBaseRate) +
              parseFloat(res.minerBaseRate) * 0.01;

            await routerInstance.methods
              .getAmountsOut(web3.utils.toWei(uprate.toString(), "ether"), [
                process.env.REACT_APP_BUSD_ADDR,
                process.env.REACT_APP_WBNB_ADDR,
              ])
              .call()
              .then(async (appvalue) => {
                console.log("valsss--", uprate, appvalue);
                var tokencontractInstance = new webb3.eth.Contract(
                  dataVal.tokenabi,
                  process.env.REACT_APP_WBNB_ADDR
                );

                var approvecontract = process.env.REACT_APP_WBNB_TREASURY;

                const estimatedGas = await tokencontractInstance.methods
                  .approve(approvecontract, appvalue[1])
                  .estimateGas({ from: account });

                await tokencontractInstance.methods
                  .approve(approvecontract, appvalue[1])
                  .send({
                    from: account,
                    gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
                    gasPrice: await web3.eth.getGasPrice(),
                  })
                  .on("receipt", async function (res) {
                    setLoadapprove(false);
                    setTokenapp(false);
                  })
                  .on("error", function (error, receipt) {
                    setLoadapprove(false);
                    console.log("error");
                  });
              });
          })
          .catch((error) => {
            setLoadapprove(false);
            console.error(error);
          });
      } catch (e) {
        setLoadapprove(false);
        console.log(e);
      }
    }
  }

  //To approve BUSD
  async function approveBUSD() {
    var x = await checkBalance();
    if (x) {
      try {
        setLoadapprove(true);
        const webb3 = new Web3(connector.provider);
        let appvalue;

        const web3 = new Web3(process.env.REACT_APP_RPC);
        const contractInstance = new web3.eth.Contract(
          dataVal.mregistryabi,
          process.env.REACT_APP_MINER_REGISTRY
        );

        await contractInstance.methods
          .fetchMinerInfo(miner_type)
          .call()
          .then((res) => (appvalue = res.minerBaseRate))
          .catch((error) => console.error(error));

        console.log("appvalue==", appvalue);
        var tokencontractInstance = new webb3.eth.Contract(
          dataVal.tokenabi,
          process.env.REACT_APP_BUSD_ADDR
        );

        var approvecontract = process.env.REACT_APP_BUSD_TREASURY;

        const estimatedGas = await tokencontractInstance.methods
          .approve(approvecontract, web3.utils.toWei(appvalue, "ether"))
          .estimateGas({ from: account });

        await tokencontractInstance.methods
          .approve(approvecontract, web3.utils.toWei(appvalue, "ether"))
          .send({
            from: account,
            gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
            gasPrice: await web3.eth.getGasPrice(),
          })
          .on("receipt", async function (res) {
            setLoadapprove(false);
            setTokenapp(false);
          })
          .on("error", function (error, receipt) {
            setLoadapprove(false);
            console.log("error");
          });
      } catch (e) {
        setLoadapprove(false);
        console.log(e);
      }
    }
  }

  //To approve CBK
  async function approveCBK() {
    var x = await checkBalance();
    console.log(x);
    if (x) {
      try {
        setLoadapprove(true);
        const webb3 = new Web3(connector.provider);
        let appvalue;

        const web3 = new Web3(process.env.REACT_APP_RPC);
        const contractInstance = new web3.eth.Contract(
          dataVal.mregistryabi,
          process.env.REACT_APP_MINER_REGISTRY
        );

        await contractInstance.methods
          .fetchMinerInfo(miner_type)
          .call()
          .then((res) => (appvalue = res.minerBaseRate))
          .catch((error) => console.error(error));

        var tokencontractInstance = new webb3.eth.Contract(
          dataVal.tokenabi,
          process.env.REACT_APP_CBK_ADDR
        );

        var approvecontract = process.env.REACT_APP_BUSD_TREASURY;

        const estimatedGas = await tokencontractInstance.methods
          .approve(approvecontract, web3.utils.toWei(appvalue, "ether"))
          .estimateGas({ from: account });

        await tokencontractInstance.methods
          .approve(approvecontract, web3.utils.toWei(appvalue, "ether"))
          .send({
            from: account,
            gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
            gasPrice: await web3.eth.getGasPrice(),
          })
          .on("receipt", async function (res) {
            setLoadapprove(false);
            setTokenapp(false);
          })
          .on("error", function (error, receipt) {
            setLoadapprove(false);
            console.log("error");
          });
      } catch (e) {
        setLoadapprove(false);
        console.log(e);
      }
    }
  }

  //To approve EGOLD V1
  async function approveEGOLDV1() {
    var x = await checkBalance();
    if (x) {
      try {
        setLoadapprove(true);
        const webb3 = new Web3(connector.provider);

        const web3 = new Web3(process.env.REACT_APP_RPC);
        const contractInstance = new web3.eth.Contract(
          dataVal.mregistryabi,
          process.env.REACT_APP_MINER_REGISTRY
        );

        const routerInstance = new web3.eth.Contract(
          dataVal.pcrouterabi,
          process.env.REACT_APP_PANCAKE_ROUTER_ADDR
        );

        await contractInstance.methods
          .fetchMinerInfo(miner_type)
          .call()
          .then(async (res) => {
            if (miner_type == 4 || miner_type == 8 || miner_type == 9)
              var uprate =
                parseFloat(res.minerBaseRate) +
                parseFloat(res.minerBaseRate) * 0.002;
            else
              var uprate =
                parseFloat(res.minerBaseRate) +
                parseFloat(res.minerBaseRate) * 0.007;

            await routerInstance.methods
              .getAmountsIn(web3.utils.toWei(uprate.toString(), "ether"), [
                process.env.REACT_APP_EGOLD_ADDR,
                process.env.REACT_APP_BUSD_ADDR,
              ])
              .call()
              .then(async (appvalue) => {
                var tokencontractInstance = new webb3.eth.Contract(
                  dataVal.tokenabi,
                  process.env.REACT_APP_EGOLD_ADDR
                );

                var approvecontract = process.env.REACT_APP_EGOLD_TREASURY;

                const estimatedGas = await tokencontractInstance.methods
                  .approve(approvecontract, appvalue[0])
                  .estimateGas({ from: account });

                await tokencontractInstance.methods
                  .approve(approvecontract, appvalue[0])
                  .send({
                    from: account,
                    gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
                    gasPrice: await web3.eth.getGasPrice(),
                  })
                  .on("receipt", async function (res) {
                    setLoadapprove(false);
                    setTokenapp(false);
                  })
                  .on("error", function (error, receipt) {
                    setLoadapprove(false);
                    console.log("error");
                  });
              });
          })
          .catch((error) => {
            setLoadapprove(false);
            console.error(error);
          });
      } catch (e) {
        setLoadapprove(false);
        console.log(e);
      }
    }
  }

    //To approve EGOLD V2
    async function approveEGOLDV2() {
      var x = await checkBalance();
      if (x) {
        try {
          setLoadapprove(true);
          const webb3 = new Web3(connector.provider);
  
          const web3 = new Web3(process.env.REACT_APP_RPC);
          const contractInstance = new web3.eth.Contract(
            dataVal.mregistryabi,
            process.env.REACT_APP_MINER_REGISTRY
          );
  
          const routerInstance = new web3.eth.Contract(
            dataVal.pcrouterabi,
            process.env.REACT_APP_PANCAKE_ROUTER_ADDR
          );
  
          await contractInstance.methods
            .fetchMinerInfo(miner_type)
            .call()
            .then(async (res) => {
              console.log("jwkhgfiwsh-")
              if (miner_type == 4 || miner_type == 8 || miner_type == 9)
                var uprate =
                  parseFloat(res.minerBaseRate) +
                  parseFloat(res.minerBaseRate) * 0.002;
              else
                var uprate =
                  parseFloat(res.minerBaseRate) +
                  parseFloat(res.minerBaseRate) * 0.007;
  
              await routerInstance.methods
                .getAmountsIn(web3.utils.toWei(uprate.toString(), "ether"), [
                  process.env.REACT_APP_EGOLDV2_ADDR,
                  process.env.REACT_APP_USDT_ADDR,
                ])
                .call()
                .then(async (appvalue) => {

      console.log("jwkhgfiwsh-",uprate)
                  var tokencontractInstance = new webb3.eth.Contract(
                    dataVal.tokenabi,
                    process.env.REACT_APP_EGOLDV2_ADDR
                  );
  
                  var approvecontract = process.env.REACT_APP_EGOLDV2_TREASURY;
  
                  const estimatedGas = await tokencontractInstance.methods
                    .approve(approvecontract, appvalue[0])
                    .estimateGas({ from: account });
  
                  await tokencontractInstance.methods
                    .approve(approvecontract, appvalue[0])
                    .send({
                      from: account,
                      gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
                      gasPrice: await web3.eth.getGasPrice(),
                    })
                    .on("receipt", async function (res) {
                      setLoadapprove(false);
                      setTokenapp(false);
                    })
                    .on("error", function (error, receipt) {
                      setLoadapprove(false);
                      console.log("error");
                    });
                });
            })
            .catch((error) => {
              setLoadapprove(false);
              console.error(error);
            });
        } catch (e) {
          setLoadapprove(false);
          console.log(e);
        }
      }
    }

  async function purchase() {
    try {
      setprocessState({ state: "processing", data: null });
      const web3 = new Web3(connector.provider);
      if (props.selected == "wbnb")
        var contractInstance = new web3.eth.Contract(
          dataVal.treasuryabi,
          process.env.REACT_APP_WBNB_TREASURY
        );
      else if (props.selected == "busd")
        var contractInstance = new web3.eth.Contract(
          dataVal.treasuryabi,
          process.env.REACT_APP_BUSD_TREASURY
        );
      else if (props.selected == "egoldv1") {
        var contractInstance = new web3.eth.Contract(
          dataVal.treasuryabi,
          process.env.REACT_APP_EGOLD_TREASURY
        );
      } else if (props.selected == "egoldv2") {
        var contractInstance = new web3.eth.Contract(
          dataVal.treasuryabi,
          process.env.REACT_APP_EGOLDV2_TREASURY
        );
      } else if (props.selected == "cbk")
        var contractInstance = new web3.eth.Contract(
          dataVal.cbktreasuryAbi,
          process.env.REACT_APP_CBK_TREASURY
        );

      const identitycontractInstance = new web3.eth.Contract(
        dataVal.identityabi,
        process.env.REACT_APP_INDENTITY_ADDR
      );
      await identitycontractInstance.methods
        .fetchUser(account)
        .call()
        .then(async (res) => {
          if (res.parent == "0x0000000000000000000000000000000000000000")
            var ref = refAddress;
          else var ref = res.parent;

          if (props.selected == "cbk") {
            console.log("cbkk", contractInstance, miner_type);

            const estimatedGas = await contractInstance.methods
              .claim(miner_type)
              .estimateGas({ from: account });

            await contractInstance.methods
              .claim(miner_type)
              .send({
                from: account,
                gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
                gasPrice: await web3.eth.getGasPrice(),
              })
              .on("receipt", async function (cres) {
                setTokenapp(true);
                setprocessState({ state: "done", data: cres.transactionHash });
              })
              .on("error", async function (e) {
                setTokenapp(true);
                setprocessState({
                  state: "error",
                  data: JSON.stringify(e.message),
                });
              });
          } else {
            const estimatedGas = await contractInstance.methods
              .purchase(ref, miner_type)
              .estimateGas({ from: account });

            await contractInstance.methods
              .purchase(ref, miner_type)
              .send({
                from: account,
                gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
                gasPrice: await web3.eth.getGasPrice(),
              })
              .on("receipt", async function (pres) {
                setTokenapp(true);
                setprocessState({ state: "done", data: pres.transactionHash });
              })
              .on("error", async function (e) {
                setTokenapp(true);
                setprocessState({
                  state: "error",
                  data: JSON.stringify(e.message),
                });
              });
          }
        });
    } catch (e) {
      console.log(e);
      setprocessState({ state: "error", data: JSON.stringify(e.message) });
    }
  }

  return (
    <>
      {" "}
      {valid == "..." ? (
        <div style={{ padding: "20px", textAlign: "center" }}>
          <Spinner />
        </div>
      ) : (
        <>
          {!valid && (
            <div class="row">
              <div class="col-md-12">
                <input
                  type="text"
                  class="form-control mb20"
                  style={{ fontSize: "13px", height: "50px" }}
                  placeholder="Please enter your Referral Address"
                  value={refAddress == "..." ? "" : refAddress}
                  onChange={(e) => {
                    setRefAddress(e.target.value);
                  }}
                  disabled={localStorage.getItem("ref")}
                />
              </div>
            </div>
          )}
          <div class="selectcurbtnsec">
            {props.selected == "..." && (
              <button class="btn-outline-color-secondary mb20 apbtn" disabled>
                Approve
              </button>
            )}
            {props.selected == "wbnb" && (
              <button
                class="btn-outline-color-secondary mb20 apbtn"
                onClick={(e) => approveWBNB()}
                disabled={!tokenapp}
              >
                {loadapprove ? <Spinner size="sm" /> : "Approve WBNB"}
              </button>
            )}
            {props.selected == "busd" && (
              <button
                class="btn-outline-color-secondary mb20 apbtn"
                onClick={(e) => approveBUSD()}
                disabled={!tokenapp}
              >
                {loadapprove ? <Spinner size="sm" /> : "Approve BUSD"}
              </button>
            )}
            {props.selected == "egoldv1" && (
              <button
                class="btn-outline-color-secondary mb20 apbtn"
                onClick={(e) => approveEGOLDV1()}
                disabled={!tokenapp}
              >
                {loadapprove ? <Spinner size="sm" /> : "Approve EGOLD (V1)"}
              </button>
            )}
             {props.selected == "egoldv2" && (
              <button
                class="btn-outline-color-secondary mb20 apbtn"
                onClick={(e) => approveEGOLDV2()}
                disabled={!tokenapp}
              >
                {loadapprove ? <Spinner size="sm" /> : "Approve EGOLD (V2)"}
              </button>
            )}
            {props.selected == "cbk" && (
              <button
                class="btn-outline-color-secondary mb20 apbtn"
                onClick={(e) => approveCBK()}
                disabled={!tokenapp}
              >
                {loadapprove ? <Spinner size="sm" /> : "Approve Credits"}
              </button>
            )}
            <button
              class="btn-color-primary mb20 paybtn"
              style={{ border: "0px" }}
              disabled={tokenapp}
              onClick={(e) => purchase()}
            >
              Pay Now
            </button>
          </div>
          {processState.state == "..." ? (
            ""
          ) : processState.state == "processing" ? (
            <LoaderCard />
          ) : processState.state == "done" ? (
            <ConfirmCard tx={processState.data} manage="true" />
          ) : (
            <ErrorCard err={processState.data} />
          )}

          {/* Insufficient bnb error modal  */}
          <Modal
            isOpen={showbnberrorModal}
            toggle={handleClosebnberr}
            style={{ marginTop: 150 }}
            classNames="errormodal"
            center
          >
            <ModalBody>
              <div style={{ textAlign: "center" }}>
                <AiOutlineInfoCircle color="#FF0000" size={48} />
                <>
                  <h2
                    style={{
                      fontSize: "19px",
                      fontWeight: 600,
                      color: "#232325",
                      padding: 10,
                    }}
                  >
                    Insufficient BNB
                  </h2>
                  <p
                    style={{
                      fontSize: 12,
                      lineHeight: "15px",
                      color: "#232325",
                      padding: 12,
                    }}
                  >
                    You need to have atleast 0.005 BNB in your wallet to pay for
                    transaction fees
                  </p>
                </>
                <div
                  style={{
                    background: "#FFC727",
                    padding: "10px 15px",
                    color: "#232325",
                    borderRadius: 4,
                    cursor: "pointer",
                  }}
                  onClick={handleClosebnberr}
                >
                  Okay
                </div>
              </div>
            </ModalBody>
          </Modal>

          {/* Insufficient token error modal  */}
          <Modal
            isOpen={showtokerrorModal}
            toggle={handleClosetokerr}
            style={{ marginTop: 150 }}
            classNames="errormodal"
            center
          >
            <ModalBody>
              <div style={{ textAlign: "center" }}>
                <AiOutlineInfoCircle color="#FF0000" size={48} />
                <>
                  <h2
                    style={{
                      fontSize: "19px",
                      fontWeight: 600,
                      color: "#232325",
                      padding: 10,
                    }}
                  >
                    Insufficient {props.selected.toUpperCase()}
                  </h2>
                  <p
                    style={{
                      fontSize: 12,
                      lineHeight: "15px",
                      color: "#232325",
                      padding: 12,
                    }}
                  >
                    You need to have atleast {props.tokprice}{" "}
                    {props.selected.toUpperCase()} in your wallet to pay for the
                    miner
                  </p>
                </>
                <div
                  style={{
                    background: "#FFC727",
                    padding: "10px 15px",
                    color: "#232325",
                    borderRadius: 4,
                    cursor: "pointer",
                  }}
                  onClick={handleClosetokerr}
                >
                  Okay
                </div>
              </div>
            </ModalBody>
          </Modal>
        </>
      )}
    </>
  );
}
