import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import InviteCard from "./ReferCards/InviteCard";
import PlanCard from "./ReferCards/PlanCard";
import QuickBonusCard from "./ReferCards/QuickBonusCard";
import EventCard from "./ReferCards/EventCard";
import CollectCard from "./ReferCards/CollectCard";
import dataVal from "../data/Abis.json";
import ConnectCard from "./TextCards/ConnectCard";
import InvalidReferCard from "./TextCards/InvalidReferCard";
import PillsMenu from "./Menu/PillsMenu";

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function Refer() {
  let navigate = useNavigate();
  const { account, isActive, connector } = useWeb3React();
  const [summaryData, setSummaryData] = useState({ data: null, loading: true });
  const [sales, setsales] = useState(0);
  const [wbnbPrice, setwbnbPrice] = useState("...");
  const [dailystate, setDailystate] = useState({ data: null, loading: true });
  const [valid, setisValid] = useState("...");

  const identitycontractInstance = new web3.eth.Contract(
    dataVal.identityabi,
    process.env.REACT_APP_INDENTITY_ADDR
  );

  async function checkRef() {
    var wAddress = localStorage.getItem("acct");
    console.log("this.here", process.env.REACT_APP_MASTER_PARENT)
    if (wAddress) {
      if (
        wAddress.toLowerCase() ==
        process.env.REACT_APP_MASTER_PARENT.toLowerCase()
      ) {
        await identitycontractInstance.methods
          .fetchUser(wAddress)
          .call()
          .then((value) => {
            setisValid(true);
            setsales(web3.utils.fromWei(value.sales, "ether"));
          })
          .catch((error) => console.error(error));
      } else {
        await identitycontractInstance.methods
          .fetchUser(localStorage.getItem("acct"))
          .call()
          .then((value) => {
            if (value.parent == "0x0000000000000000000000000000000000000000") {
              setisValid(false);
            } else {
              setisValid(true);
            }
            setsales(web3.utils.fromWei(value.sales, "ether"));
          })
          .catch((error) => console.error(error));
      }
    }
    return 1;
  }

  useEffect(() => {
    checkRef();
    getSummary();
  }, []);

  const getSummary = async () => {
    var wAddress = localStorage.getItem("acct");
    if (wAddress) {
      const url =
        "https://egold-treasury.tagdev.info/v1/summary/dashboard/" +
        localStorage.getItem("acct");

      fetch(url)
        .then(function (response) {
          return response.json();
        })
        .then(function (newData) {
          setSummaryData({ data: newData, loading: false });
        })
        .catch(function (error) {
          console.log("Requestfailed", error);
        });

      const urlwbnb =
        "https://api.coingecko.com/api/v3/simple/price?ids=wbnb&vs_currencies=usd";
      fetch(urlwbnb, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((json) => {
          setwbnbPrice(json.wbnb.usd);
        })
        .catch((error) => {
          console.error(error);
        });

      const url24 =
        "https://egold-marketdata.herokuapp.com/v1/summary/getLatestTrades/20/";
      //fetch 24 hour market data
      fetch(url24)
        .then(function (response) {
          return response.json();
        })
        .then(function (newData) {
          console.log("TAAGG EGOLD PRICEEE", newData);
          setDailystate({ data: newData, loading: false });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  return (
    <>
      <div class="mainsection">
        <div class="minertabsec secpadding lightgrey-bg brtlr">
          <PillsMenu activeval="refer" />

          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-refer"
              role="tabpanel"
              aria-labelledby="pills-refer-tab"
              tabindex="0"
            >
              {" "}
              <ConnectCard />
              <div
                style={
                  localStorage.getItem("acct") || account
                    ? {}
                    : { display: "none" }
                }
              >
                {valid == "..." ? (
                  <div style={{ padding: "20px", textAlign: "center" }}>
                    <Spinner />
                  </div>
                ) : (
                  <>
                    {valid ? <InviteCard /> : <InvalidReferCard />}
                    <PlanCard refervalid={valid} />
                    {/* <QuickBonusCard refervalid={valid} /> */}
                    {/* <EventCard refervalid={valid} /> */}
                    <CollectCard refervalid={valid} />

                    <div class="refertilerow">
                      <div class="refertile">
                        <p class="tilesphead">Direct Sales</p>
                        <hr />
                        <p class="tilespdel">
                          $
                          {summaryData.loading === true
                            ? "...."
                            : summaryData.data.dirAmount
                              ? noround(summaryData.data.dirAmount, 2)
                              : "0.00"}{" "}
                        </p>
                      </div>
                      <div class="refertile">
                        <p class="tilesphead">Team Sales</p>
                        <hr />
                        <p class="tilespdel">
                          ${valid ? noround(sales, 2) : "...."}
                        </p>
                      </div>
                    </div>
                    <div class="refertilerow">
                      <div class="refertile">
                        <p class="tilesphead">Direct Commission</p>
                        <hr />
                        <p class="tilespdel">
                          $
                          {summaryData.loading === true
                            ? "...."
                            : noround(
                              (summaryData.data.dirComm_WBNB &&
                                wbnbPrice !== "..."
                                ? summaryData.data.dirComm_WBNB * wbnbPrice
                                : 0) +
                              (summaryData.data.dirComm_BUSD
                                ? summaryData.data.dirComm_BUSD
                                : 0) +
                              (summaryData.data.dirComm
                                ? summaryData.data.dirComm
                                : 0) +
                                (summaryData.data.dirComm_EGOLD &&
                                  !dailystate.loading
                                  ? summaryData.data.dirComm_EGOLD *
                                  dailystate.data[0].TAG_USD
                                  : 0) +
                                  (summaryData.data.dirComm_EGOLDV2 &&
                                    !dailystate.loading
                                    ? summaryData.data.dirComm_EGOLDV2 *
                                    dailystate.data[0].TAG_USD
                                    : 0),
                              2
                            )}{" "}
                        </p>
                      </div>
                      <div class="refertile">
                        <p class="tilesphead">Indirect Commission</p>
                        <hr />
                        <p class="tilespdel">
                          $
                          {summaryData.loading === true
                            ? "...."
                            : noround(
                              (summaryData.data.inComm_WBNB &&
                                wbnbPrice !== "..."
                                ? summaryData.data.inComm_WBNB * wbnbPrice
                                : 0) +
                              (summaryData.data.inComm_BUSD
                                ? summaryData.data.inComm_BUSD
                                : 0) +
                                (summaryData.data.inComm_EGOLD &&
                                  !dailystate.loading
                                  ? summaryData.data.inComm_EGOLD *
                                  dailystate.data[0].TAG_USD
                                  : 0) +
                                  (summaryData.data.inComm_EGOLDV2 &&
                                    !dailystate.loading
                                    ? summaryData.data.inComm_EGOLDV2 *
                                    dailystate.data[0].TAG_USD
                                    : 0) +
                              (summaryData.data.inComm
                                ? summaryData.data.inComm
                                : 0),
                              2
                            )}{" "}
                        </p>
                      </div>
                    </div>
                    <div class="refertilerow">
                      <div class="refertile">
                        <p class="tilesphead">Users referred</p>
                        <hr />
                        <p class="tilespdel">
                          {" "}
                          {summaryData.loading === true
                            ? "...."
                            : summaryData.data.invites.length}
                        </p>
                      </div>
                      <div class="refertile">
                        <p class="tilesphead">Tree level</p>
                        <hr />
                        {valid ? (
                          <p class="tilespdel">
                            {" "}
                            {summaryData.loading === true
                              ? "...."
                              : summaryData.data.idepth
                                ? summaryData.data.idepth
                                : "0"}{" "}
                            level deep
                          </p>
                        ) : (
                          <p class="tilespdel">....</p>
                        )}
                      </div>
                    </div>

                    {valid ? (
                      <div class="selectcurbtnsec">
                        <a
                          class="btn-outline-color-secondary mb20"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate("/downlinetree");
                          }}
                        >
                          Explore Downline
                        </a>
                        <a
                          class="btn-color-primary mb20"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate("/refcalculator");
                          }}
                        >
                          Referral calculator
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="lightblue-bg secpadding brblr mb20">
          <p class="text1">
            Click on 'Explore Downline' to evaluate your downline performance
            and use the Referral Calculator to calculate your indirect earnings.
          </p>
        </div>
      </div>
    </>
  );
}
