import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";

function Exref() {
  let history = useNavigate();

  let params = useParams();
  let ref_id = params.id;

  if (ref_id) {
    localStorage.setItem("ref", ref_id);
  }

  useEffect(() => {
    window.location.href = "https://www.egoldminer.shop/";
  }, []);

  return (
    <>
      <div className="container1 m-0 p-0 ">
        <div style={{ padding: "50px" }}>
          <Spinner />
        </div>
      </div>
    </>
  );
}
export default Exref;
