import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import dataVal from "../data/Abis.json";
import TreeComponent from "./AppRefTree";
import { BiArrowBack, BiSolidCopyAlt } from "react-icons/bi";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);
export default function Insure() {
  const { account, isActive, connector } = useWeb3React();
  const CONTRACT_ADDRESS = process.env.REACT_APP_INDENTITY_ADDR;
  const CONTRACT_ABI = dataVal.identityabi;
  const contractInstance = new web3.eth.Contract(
    CONTRACT_ABI,
    CONTRACT_ADDRESS
  );

  let navigate = useNavigate();

  const [dailystate, setDailystate] = useState({ data: null, loading: true }); //egold price
  const [sales, setsales] = useState(0);
  const [summaryData, setSummaryData] = useState({ data: null, loading: true });
  const [wbnbClaimTearn, setwbnbClaimTearn] = useState("...");
  const [wbnbPrice, setwbnbPrice] = useState("...");
  const [parent, setParent] = useState("...");
  const [busdClaimTearn, setbusdClaimTearn] = useState("...");
  const [egoldClaimTearn, setegoldClaimTearn] = useState("...");

  const IDENTITY_ADDR = process.env.REACT_APP_INDENTITY_ADDR;
  const IDENTITY_ABI = dataVal.identityabi;
  var TreasuryABI = dataVal.treasuryabi;

  useEffect(() => {
    if (isActive) {
      console.log("isActive");
      getSummary();
    } else {
      console.log("inactive");
      if (!localStorage.getItem("acct")) navigate("/buy");
    }
  }, [account]);

  const getSummary = async () => {
    const identitycontractInstance = new web3.eth.Contract(
      IDENTITY_ABI,
      IDENTITY_ADDR
    );
    if (localStorage.getItem("acct") && account) {
      await identitycontractInstance.methods
        .fetchUser(account)
        .call()
        .then((res) => {
          if (res.parent !== "0x0000000000000000000000000000000000000000") {
            setParent(res.parent);
          }
        });
    }
    var wAddress = localStorage.getItem("acct");
    if (wAddress) {
      await contractInstance.methods
        .fetchUser(localStorage.getItem("acct"))
        .call()
        .then(
          (value) =>
            console.log("saless,", value) +
            setsales(web3.utils.fromWei(value.sales, "ether"))
        )
        .catch((error) => console.error(error));
    }
    const url =
      "https://egold-treasury.tagdev.info/v1/summary/dashboard/" +
      localStorage.getItem("acct");

    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        setSummaryData({ data: newData, loading: false });
      })
      .catch(function (error) {
        console.log("Requestfailed", error);
      });
    const url24 =
      "https://egold-marketdata.herokuapp.com/v1/summary/getLatestTrades/20/";
    //fetch 24 hour market data
    fetch(url24)
      .then(function (response) {
        return response.json();
      })
      .then(function (newData) {
        console.log("TAAGG EGOLD PRICEEE", newData);
        setDailystate({ data: newData, loading: false });
      })
      .catch(function (error) {
        console.log(error);
      });
    const urlprice =
      "https://api.coingecko.com/api/v3/simple/price?ids=wbnb&vs_currencies=usd";
    fetch(urlprice, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((json) => {
        console.log("BNB RESPONSE", json);
        setwbnbPrice(json.wbnb.usd);
      });
    var contractInstancewbnb = new web3.eth.Contract(
      TreasuryABI,
      process.env.REACT_APP_WBNB_TREASURY
    );
    var contractInstancebusd = new web3.eth.Contract(
      TreasuryABI,
      process.env.REACT_APP_BUSD_TREASURY
    );
    var contractInstanceegold = new web3.eth.Contract(
      TreasuryABI,
      process.env.REACT_APP_EGOLD_TREASURY
    );

    await contractInstancewbnb.methods
      .fetchCommission(localStorage.getItem("acct"))
      .call()
      .then(
        (value) =>
          console.log("valuessss", value) +
          setwbnbClaimTearn(web3.utils.fromWei(value, "ether"))
      )
      .catch((error) => console.error(error));

    await contractInstancebusd.methods
      .fetchCommission(localStorage.getItem("acct"))
      .call()
      .then(
        (value) =>
          console.log("valuessss11", value) +
          setbusdClaimTearn(web3.utils.fromWei(value, "ether"))
      )
      .catch((error) => console.error(error));

    await contractInstanceegold.methods
      .fetchCommission(localStorage.getItem("acct"))
      .call()
      .then((value) => setegoldClaimTearn(web3.utils.fromWei(value, "ether")))
      .catch((error) => console.error(error));
  };
  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }

  //to not round values while showing balance
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  return (
    <>
      <div style={{ padding: "0 20px" }}>
        <div className="container" style={{ padding: 0 }}>
          <div className="row treebox" id="row3">
            <a
              style={{ cursor: "pointer", padding: 0 }}
              onClick={() => {
                navigate("/refer");
              }}
            >
              <div style={{ display: "flex", margin: "10px 0px", gap: 10 }}>
                <BiArrowBack style={{ fontSize: "20px", marginTop: 5 }} />

                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    marginBottom: 0,
                  }}
                >
                  Explore Downline
                </p>
              </div>
            </a>
            <div className="card mx-0 me-0 downlinecard" id="card4">
              <div className="row px-3">
                <div className=" col-xl-10 col-9 p-0">
                  <p className="text12 mx-1">Your Details</p>
                </div>
                <div className="col-xl-2 col-3 p-0"></div>
              </div>
              <hr className="mt-0" style={{ color: "#b0b6c0" }} />
              <div className="row px-3 mt-1 my-3">
                <div className="col-3 cols-6 mob-mb-25 p-0 text-start">
                  <p className="text5 m-0">
                    Your <br className="brnone" /> Parent{" "}
                    <BiSolidCopyAlt
                      style={{ fontSize: "14px", cursor: "pointer" }}
                      onClick={async () => {
                        await navigator.clipboard.writeText(parent);
                      }}
                    />
                  </p>
                  <p
                    className="text6 m-0 pt-2"
                    style={{ color: "#4F6B75", cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://bscscan.com/address/" + parent,
                        "_blank"
                      )
                    }
                  >
                    {parent == "..."
                      ? parent
                      : parent.slice(0, 5) + "....." + parent.slice(-5)}
                  </p>
                </div>
                <div className="col-3 cols-6 mob-mb-25 p-0 text-center">
                  <p className="text5 m-0">
                    Your <br className="brnone" /> Rank
                  </p>
                  <p className="text6 m-0 pt-2 ">
                    {localStorage.getItem("lev") == "17"
                      ? "16"
                      : localStorage.getItem("lev")}
                  </p>
                </div>
                <div className="col-3 cols-6 p-0 text-center">
                  <p className="text5 m-0">
                    Your <br className="brnone" /> Sales (USD)
                  </p>
                  <p className="text6 m-0 pt-2">
                    $
                    {summaryData.loading === true || sales == "..."
                      ? "..."
                      : summaryData.data.dirAmount
                      ? noround(
                          parseFloat(summaryData.data.dirAmount) +
                            parseFloat(sales),
                          2
                        )
                      : "0.00"}
                  </p>
                </div>
                <div className="col-3 cols-6 p-0 text-end">
                  <p className="text5 m-0">
                    Your <br className="brnone" /> Earnings (USD)
                  </p>
                  <p className="text6 m-0 pt-2">
                    $
                    {wbnbClaimTearn !== "..." &&
                    wbnbPrice !== "..." &&
                    !dailystate.loading &&
                    busdClaimTearn !== "..." &&
                    egoldClaimTearn !== "..."
                      ? numFormatter(
                          parseFloat(wbnbClaimTearn) * wbnbPrice +
                            parseFloat(busdClaimTearn) +
                            parseFloat(egoldClaimTearn) *
                              dailystate.data[0].TAG_USD
                        )
                      : "..."}
                  </p>
                </div>
              </div>
              <div
                className="row earnedpsec"
                style={{
                  borderRadius: "0px 0px 14px 14px",
                  background: "#E0E7EA",
                }}
              >
                <p className="earnedp">
                  The card displays your performance. Use the plus symbol below
                  to expand and evaluate your downline performance and rank.
                </p>
              </div>
            </div>
            <div
              style={{
                background: "#EFF3F4",
                borderRadius: 15,
                marginTop: "30px",
                padding: 20,
              }}
            >
              <h4>Your Downline</h4>
              <TreeComponent />
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
}
