import React, { useEffect, useState } from "react";
import BackButton from "./Buttons/BackButton/BackButtonwState";
import PurchaseButton from "./Buttons/PurchaseButton/PurchaseButton";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SelectMiner from "./SelectPay/SelectMiner";
import SelectPayCard from "./SelectPay/SelectPayCard";
import ConvertCardUSDT from "./EGConverter/ConvertCardUSDT";
import dataVal from "../data/Abis.json";

export default function SelectPay() {
  let navigate = useNavigate();
  let params = useParams();
  let location = useLocation();

  let miner_type = location.state;
  const Web3 = require("web3");
  const web3 = new Web3(process.env.REACT_APP_RPC);

  const [selCurrency, setSelCurrency] = useState("...");
  const [minerName, setMinerName] = useState("...");
  const [minerPrice, setMinerPrice] = useState("...");
  const [selectedPrice, setselectedPrice] = useState("...");

  const updateValue = (newValue) => {
    setselectedPrice(newValue);
  };

  useEffect(() => {
    getMinerInfo();
  }, []);

  const getMinerInfo = async () => {
    const contractInstance = new web3.eth.Contract(
      dataVal.mregistryabi,
      process.env.REACT_APP_MINER_REGISTRY
    );

    await contractInstance.methods
      .fetchMinerInfo(miner_type)
      .call()
      .then((res) => {
        setMinerName(res.name);
        setMinerPrice(parseInt(res.minerBaseRate));
      });
  };

  return (
    <>
      <div class="mainsection">
        <div class="tabsec secpadding lightgrey-bg brtlr">
          <BackButton
            Title="Select payment currency"
            ReRoute={"buy/" + miner_type}
            Stateval={miner_type}
          />

          <SelectMiner type={miner_type} name={minerName} price={minerPrice} />
          <div class="detailCard secpadding mb20">
            <p class="text1 mb10">Select payment currency</p>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelCurrency("egoldv1");
              }}
            >
              <SelectPayCard
                currency="egoldv1"
                address="0x8005D97E993668a528008d16338B42f9e976ED0F"
                price={minerPrice}
                convert="true"
                selected={selCurrency}
                updateParentValue={updateValue}
              />
            </div>

            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelCurrency("egoldv2");
              }}
            >
              <SelectPayCard
                currency="egoldv2"
                address="0xBE76F927d274072266caDe09Daa54750cd4293a1"
                price={minerPrice}
                convert="true"
                selected={selCurrency}
                updateParentValue={updateValue}
              />
            </div>

            {/* <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelCurrency("wbnb");
              }}
            >
              <SelectPayCard
                currency="wbnb"
                address="0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                price={minerPrice}
                convert="true"
                selected={selCurrency}
                updateParentValue={updateValue}
              />

            </div>

            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelCurrency("busd");
              }}
            >

              <SelectPayCard
                currency="busd"
                address="0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
                price={minerPrice}
                convert="false"
                selected={selCurrency}
                updateParentValue={updateValue}
              />

            </div> */}
            {/* <div className="containerbuy">
              <div className="contentbuy">
                <SelectPayCard
                  currency="cbk"
                  address="0x14E3460453412Da9D4e635a4292eC95F360E5bF8"
                  price={minerPrice}
                  convert="false"
                  selected={selCurrency}
                  updateParentValue={updateValue}
                />
              </div>
              <div className="overlaybuy"></div>
            </div> */}


            {/* <div
              style={{ cursor: "pointer", }}
              onClick={() => {
                setSelCurrency("wbnb");
              }}
            >
              <SelectPayCard
                currency="wbnb"
                address="0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                price={minerPrice}
                convert="true"
                selected={selCurrency}
                updateParentValue={updateValue}
              />
            </div> */}
            {/* <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelCurrency("busd");
              }}
            >
              <SelectPayCard
                currency="busd"
                address="0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56"
                price={minerPrice}
                convert="false"
                selected={selCurrency}
                updateParentValue={updateValue}
              />
            </div>*/}
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelCurrency("cbk");
              }}
            >
              <SelectPayCard
                currency="cbk"
                address="0x14E3460453412Da9D4e635a4292eC95F360E5bF8"
                price={minerPrice}
                convert="false"
                selected={selCurrency}
                updateParentValue={updateValue}
              />
            </div>
          </div>
          <PurchaseButton
            selected={selCurrency}
            miner={miner_type}
            tokprice={selectedPrice}
          />
        </div>
        <div class="lightblue-bg secpadding brblr mb20">
          <p class="text1">
            You can pay for Egold Miners with multiple currencies. Please choose
            one from the list above.
          </p>
        </div>
        <ConvertCardUSDT price={minerPrice}/>
      </div >
    </>
  );
}
