import React, { useEffect, useState } from "react";
import BackButton from "./Buttons/BackButton/BackButton";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import S1Img from "../images/miners/S1.png";
import S2Img from "../images/miners/S2.png";
import S3Img from "../images/miners/S3.png";
import S4Img from "../images/miners/S4.png";
import S5Img from "../images/miners/S5.png";
import { Spinner } from "reactstrap";
const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function QuickStart() {
  let navigate = useNavigate();
  const { account, isActive, connector } = useWeb3React();

  const [typeval, setData] = useState(null);
  const [currentblockNumber, setcurrentblockNumber] = useState("...");

  const apiUrl =
    "https://egold-treasury.tagdev.info/v1/summary/fsMinersOf/" + account;

  const fetchData = async () => {
    try {
      if (account) {
        const cbn = await web3.eth.getBlockNumber();
        setcurrentblockNumber(cbn);
        console.log("fetching");
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        console.log(jsonData);
        setData(jsonData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [account]);

  const postDataToApi = async (type) => {
    try {
      const foundObject = typeval.eligiblePurchases.find(
        (obj) => obj.mType == type
      );

      if (foundObject) {
        console.log("Found:", foundObject);
        const apiUrl =
          "https://egold-treasury.tagdev.info/v1/summary/createFS/";
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            addr: foundObject.buyer,
            txn: foundObject._id,
          }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        console.log("response", responseData);
        if (responseData.error == 0) {
          navigate(
            "/quicktrack/" + foundObject.buyer + "_" + foundObject.mType
          );
        }
      } else {
        console.log("Not found");
      }
    } catch (error) {
      console.error("Error posting data:", error);
    }
  };

  return (
    <div class="mainsection">
      <div class="tabsec secpadding lightgrey-bg brrr mb20">
        <BackButton Title="Start" ReRoute="refer" />
        {typeval == null || currentblockNumber == "..." ? (
          <div style={{ textAlign: "center" }}>
            <Spinner />
          </div>
        ) : (
          <>
            <div style={{ padding: 10 }}>
              <div class="wallettokenrow quickstartdiv">
                <div class="selectcurrowcontent" style={{ padding: 12 }}>
                  <div class="selectcurimgside">
                    <div class="curicon">
                      <img
                        src={S1Img}
                        class="img-fluid"
                        style={{ height: 50 }}
                      />
                    </div>
                    <div class="curicondel">
                      <p className="marginzero egoldminertab">
                        Egold Miner{" "}
                        <p className="marginzero" style={{ fontWeight: "600" }}>
                          S1
                        </p>
                      </p>
                    </div>
                  </div>
                  <div class="selectdetailside">
                    {typeval.FS.some((obj) => obj.type === 5) ? (
                      typeval.FS.find((obj) => obj.type === 5).claimSilver ==
                        true ||
                        typeval.FS.find((obj) => obj.type === 5).end2 <
                        currentblockNumber ? (
                        <button
                          className="btn-bg-yellow quickcompleted"
                          onClick={() =>
                            navigate(
                              "/quicktrack/" +
                              typeval.FS.find((obj) => obj.type === 5)._id
                            )
                          }
                        >
                          Completed
                        </button>
                      ) : (
                        <button
                          className="btn-bg-yellow quickactivate"
                          onClick={() =>
                            navigate(
                              "/quicktrack/" +
                              typeval.FS.find((obj) => obj.type === 5)._id
                            )
                          }
                        >
                          Track Progress
                        </button>
                      )
                    ) : typeval.eligiblePurchases.some(
                      (obj) => obj.mType === 5
                    ) ? (
                      <button
                        className="btn-bg-yellow quickactivate"
                        onClick={() => postDataToApi(5)}
                      >
                        Activate
                      </button>
                    ) : (
                      <button
                        className="quickbuy"
                        onClick={() => navigate("/buy/S1", { state: 5 })}
                      >
                        Buy now
                      </button>
                    )}
                  </div>
                </div>
                <div
                  class="lightblue-bg brblr quickstartbn"
                  style={{ padding: 12 }}
                >
                  <p className="textquick">Quick start bonus</p>
                  <p className="textquickend">20 CBK = $20</p>
                </div>
              </div>
            </div>
            <div style={{ padding: 10 }}>
              <div
                class="wallettokenrow quickstartdiv"
                style={{ border: "1px solid #D9D9D9" }}
              >
                <div class="selectcurrowcontent" style={{ padding: 12 }}>
                  <div class="selectcurimgside">
                    <div class="curicon">
                      <img
                        src={S2Img}
                        class="img-fluid"
                        style={{ height: 50 }}
                      />
                    </div>
                    <div class="curicondel">
                      <p className="marginzero egoldminertab">
                        Egold Miner{" "}
                        <p className="marginzero" style={{ fontWeight: "600" }}>
                          S2
                        </p>
                      </p>
                    </div>
                  </div>
                  <div class="selectdetailside">
                    {typeval.FS.some((obj) => obj.type === 6) ? (
                      typeval.FS.find((obj) => obj.type === 6).claimSilver ==
                        true ||
                        typeval.FS.find((obj) => obj.type === 6).end2 <
                        currentblockNumber ? (
                        <button
                          className="btn-bg-yellow quickcompleted"
                          onClick={() =>
                            navigate(
                              "/quicktrack/" +
                              typeval.FS.find((obj) => obj.type === 6)._id
                            )
                          }
                        >
                          Completed
                        </button>
                      ) : (
                        <button
                          className="btn-bg-yellow quickactivate"
                          onClick={() =>
                            navigate(
                              "/quicktrack/" +
                              typeval.FS.find((obj) => obj.type === 6)._id
                            )
                          }
                        >
                          Track Progress
                        </button>
                      )
                    ) : typeval.eligiblePurchases.some(
                      (obj) => obj.mType === 6
                    ) ? (
                      <button
                        className="btn-bg-yellow quickactivate"
                        onClick={() => postDataToApi(6)}
                      >
                        Activate
                      </button>
                    ) : (
                      <button
                        className="quickbuy"
                        onClick={() => navigate("/buy/S2", { state: 6 })}
                      >
                        Buy now
                      </button>
                    )}
                  </div>
                </div>
                <div
                  class="lightblue-bg brblr quickstartbn"
                  style={{ padding: 12 }}
                >
                  <p className="textquick">Quick start bonus</p>
                  <p className="textquickend">100 CBK = $100</p>
                </div>
              </div>
            </div>
            <div style={{ padding: 10 }}>
              <div
                class="wallettokenrow quickstartdiv"
                style={{ border: "1px solid #D9D9D9" }}
              >
                <div class="selectcurrowcontent" style={{ padding: 12 }}>
                  <div class="selectcurimgside">
                    <div class="curicon">
                      <img
                        src={S3Img}
                        class="img-fluid"
                        style={{ height: 50 }}
                      />
                    </div>
                    <div class="curicondel">
                      <p className="marginzero egoldminertab">
                        Egold Miner{" "}
                        <p className="marginzero" style={{ fontWeight: "600" }}>
                          S3
                        </p>
                      </p>
                    </div>
                  </div>
                  <div class="selectdetailside">
                    {typeval.FS.some((obj) => obj.type === 7) ? (
                      typeval.FS.find((obj) => obj.type === 7).claimSilver ==
                        true ||
                        typeval.FS.find((obj) => obj.type === 7).end2 <
                        currentblockNumber ? (
                        <button
                          className="btn-bg-yellow quickcompleted"
                          onClick={() =>
                            navigate(
                              "/quicktrack/" +
                              typeval.FS.find((obj) => obj.type === 7)._id
                            )
                          }
                        >
                          Completed
                        </button>
                      ) : (
                        <button
                          className="btn-bg-yellow quickactivate"
                          onClick={() =>
                            navigate(
                              "/quicktrack/" +
                              typeval.FS.find((obj) => obj.type === 7)._id
                            )
                          }
                        >
                          Track Progress
                        </button>
                      )
                    ) : typeval.eligiblePurchases.some(
                      (obj) => obj.mType === 7
                    ) ? (
                      <button
                        className="btn-bg-yellow quickactivate"
                        onClick={() => postDataToApi(7)}
                      >
                        Activate
                      </button>
                    ) : (
                      <button
                        className="quickbuy"
                        onClick={() => navigate("/buy/S3", { state: 7 })}
                      >
                        Buy now
                      </button>
                    )}
                  </div>
                </div>
                <div
                  class="lightblue-bg brblr quickstartbn"
                  style={{ padding: 12 }}
                >
                  <p className="textquick">Quick start bonus</p>
                  <p className="textquickend">500 CBK = $500</p>
                </div>
              </div>
            </div>
            <div style={{ padding: 10 }}>
              <div
                class="wallettokenrow quickstartdiv"
                style={{ border: "1px solid #D9D9D9" }}
              >
                <div class="selectcurrowcontent" style={{ padding: 12 }}>
                  <div class="selectcurimgside">
                    <div class="curicon">
                      <img
                        src={S4Img}
                        class="img-fluid"
                        style={{ height: 50 }}
                      />
                    </div>
                    <div class="curicondel">
                      <p className="marginzero egoldminertab">
                        Egold Miner{" "}
                        <p className="marginzero" style={{ fontWeight: "600" }}>
                          S4
                        </p>
                      </p>
                    </div>
                  </div>
                  <div class="selectdetailside">
                    {typeval.FS.some((obj) => obj.type === 8) ? (
                      typeval.FS.find((obj) => obj.type === 8).claimSilver ==
                        true ||
                        typeval.FS.find((obj) => obj.type === 8).end2 <
                        currentblockNumber ? (
                        <button
                          className="btn-bg-yellow quickcompleted"
                          onClick={() =>
                            navigate(
                              "/quicktrack/" +
                              typeval.FS.find((obj) => obj.type === 8)._id
                            )
                          }
                        >
                          Completed
                        </button>
                      ) : (
                        <button
                          className="btn-bg-yellow quickactivate"
                          onClick={() =>
                            navigate(
                              "/quicktrack/" +
                              typeval.FS.find((obj) => obj.type === 8)._id
                            )
                          }
                        >
                          Track Progress
                        </button>
                      )
                    ) : typeval.eligiblePurchases.some(
                      (obj) => obj.mType === 8
                    ) ? (
                      <button
                        className="btn-bg-yellow quickactivate"
                        onClick={() => postDataToApi(8)}
                      >
                        Activate
                      </button>
                    ) : (
                      <button
                        className="quickbuy"
                        onClick={() => navigate("/buy/S4", { state: 8 })}
                      >
                        Buy now
                      </button>
                    )}
                  </div>
                </div>
                <div
                  class="lightblue-bg brblr quickstartbn"
                  style={{ padding: 12 }}
                >
                  <p className="textquick">Quick start bonus</p>
                  <p className="textquickend">2500 CBK = $2500</p>
                </div>
              </div>
            </div>
            <div style={{ padding: 10 }}>
              <div
                class="wallettokenrow quickstartdiv"
                style={{ border: "1px solid #D9D9D9" }}
              >
                <div class="selectcurrowcontent" style={{ padding: 12 }}>
                  <div class="selectcurimgside">
                    <div class="curicon">
                      <img
                        src={S5Img}
                        class="img-fluid"
                        style={{ height: 50 }}
                      />
                    </div>
                    <div class="curicondel">
                      <p className="marginzero egoldminertab">
                        Egold Miner{" "}
                        <p className="marginzero" style={{ fontWeight: "600" }}>
                          S5
                        </p>
                      </p>
                    </div>
                  </div>
                  <div class="selectdetailside">
                    {typeval.FS.some((obj) => obj.type === 9) ? (
                      typeval.FS.find((obj) => obj.type === 9).claimSilver ==
                        true ||
                        typeval.FS.find((obj) => obj.type === 9).end2 <
                        currentblockNumber ? (
                        <button
                          className="btn-bg-yellow quickcompleted"
                          onClick={() =>
                            navigate(
                              "/quicktrack/" +
                              typeval.FS.find((obj) => obj.type === 9)._id
                            )
                          }
                        >
                          Completed
                        </button>
                      ) : (
                        <button
                          className="btn-bg-yellow quickactivate"
                          onClick={() =>
                            navigate(
                              "/quicktrack/" +
                              typeval.FS.find((obj) => obj.type === 9)._id
                            )
                          }
                        >
                          Track Progress
                        </button>
                      )
                    ) : typeval.eligiblePurchases.some(
                      (obj) => obj.mType === 9
                    ) ? (
                      <button
                        className="btn-bg-yellow quickactivate"
                        onClick={() => postDataToApi(9)}
                      >
                        Activate
                      </button>
                    ) : (
                      <button
                        className="quickbuy"
                        onClick={() => navigate("/buy/S5", { state: 9 })}
                      >
                        Buy now
                      </button>
                    )}
                  </div>
                </div>
                <div
                  class="lightblue-bg brblr quickstartbn"
                  style={{ padding: 12 }}
                >
                  <p className="textquick">Quick start bonus</p>
                  <p className="textquickend">5000 CBK = $5000</p>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="rulesdiv">
          <button
            className="rulesbtn"
            onClick={() => {
              navigate("/quickrules");
            }}
          >
            Rules
          </button>
        </div>
      </div>
    </div>
  );
}
