import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { BitlyClient } from "bitly";
import dataVal from "../../data/Abis.json";
const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);
export default function MinerCard(props) {
  let navigate = useNavigate();
  const [shorturl, setShorturl] = useState("");
  const [IScodecopied, setIScodecopied] = useState(false);
  const [ISurlcopied, setISurlcopied] = useState(false);

  let shortify = "";
  const [modalV, setmodalV] = useState(false);
  const bitly = new BitlyClient("8aaff7125aad69262c04e08a229a40dbf1ebf650", {});

  const toggleModal = () => {
    setmodalV(!modalV);
  };

  async function levelfetch() {
    var wAddress = localStorage.getItem("acct");
    if (wAddress) {
      let isValidRef = await checkRef();
      if (
        isValidRef != "0x0000000000000000000000000000000000000000" ||
        wAddress.toLowerCase() ==
        process.env.REACT_APP_MASTER_PARENT.toLowerCase()
      ) {
        shortify = await bitly.shorten(
          "https://web3.egoldminer.shop/exref/" + localStorage.getItem("acct")
        );
        setShorturl(shortify.link);
      }
    }
  }

  async function checkRef() {
    var wAddress = localStorage.getItem("acct");
    if (wAddress) {
      const CONTRACT_ADDRESS = process.env.REACT_APP_INDENTITY_ADDR;
      const CONTRACT_ABI = dataVal.identityabi;
      const contractInstance = new web3.eth.Contract(
        CONTRACT_ABI,
        CONTRACT_ADDRESS
      );

      await contractInstance.methods
        .fetchUser(wAddress)
        .call()
        .then((value) => {
          return value.parent;
        })
        .catch((error) => console.error(error));
    }
  }

  useEffect(() => {
    levelfetch();
  }, []);

  return (
    <>
      <div class="walletamtsec mb20">
        <div class="refercardbg secpadding brtlr">
          <p
            class="referinvitep"
            style={{ fontWeight: 600, marginBottom: "3px" }}
          >
            Invite Code
          </p>
          <p class="referinvitep wordbreak inviaddress">
            {localStorage.getItem("acct")}
          </p>
        </div>
        <div class="bg-white secpadding brblr">
          <div class="selectcurbtnsec">
            <a
              class="btn-outline-color-secondary mb20 referinvibtns"
              onClick={async () => {
                await navigator.clipboard.writeText(
                  localStorage.getItem("acct")
                );
                setIScodecopied(true);
                setTimeout(function () {
                  setIScodecopied(false);
                }, 2000);
              }}
            >
              <i class="fas fa-copy"></i>
              {IScodecopied ? "Copied" : "Copy Code"}{" "}
            </a>
            <a
              class="btn-outline-color-secondary mb20 referinvibtns"
              onClick={() => {
                toggleModal();
              }}
            >
              <i class="fa fa-qrcode"></i>Open QR Code{" "}
            </a>
          </div>
          <div class="selectcurbtnsec">
            <a
              class="btn-outline-color-secondary referinvibtns"
              onClick={async () => {
                await navigator.clipboard.writeText(shorturl);
                setISurlcopied(true);
                setTimeout(function () {
                  setISurlcopied(false);
                }, 2000);
              }}
            >
              <i class="fa fa-light fa-globe"></i>
              {ISurlcopied ? "Copied" : "Copy URL"}{" "}
            </a>
            <a
              class="btn-outline-color-secondary referinvibtns"
              onClick={async () => {
                await navigator.share({ text: shorturl });
              }}
            >
              <i class="fa fa-share-alt"></i>Share URL{" "}
            </a>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalV}
        toggle={toggleModal}
        size="sm"
        backdrop="static"
        backdropClassName="connectmodalbg"
      >
        <ModalHeader
          toggle={toggleModal}
          style={{
            color: "black",
            padding: "0.8rem",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              textTransform: "uppercase",
              fontWeight: "600",
              color: "#000",
            }}
          >
            Referral Code :
          </div>
        </ModalHeader>
        <ModalBody
          style={{
            background: "#fff",
            borderRadius: "25px",
            textAlign: "center",
            padding: "50px 30px",
          }}
        >
          <QRCode
            value={
              "https://web3.egoldminer.shop/exref/" + localStorage.getItem("acct")
            }
            size="200"
          />
        </ModalBody>
      </Modal>
    </>
  );
}
