import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { Spinner } from "reactstrap";
import updown from "../../images/updown.svg";
import dataVal from "../../data/Abis.json";
import LoaderCard from "../LoaderCard/LoaderCard";
import ConfirmCard from "../LoaderCard/ConfirmCard";
import ErrorCard from "../LoaderCard/ErrorCard";

const Web3 = require("web3");
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function MinerCard(props) {
  let navigate = useNavigate();
  const { account, isActive, connector } = useWeb3React();

  const [processState, setprocessState] = useState({
    state: "...",
    data: null,
  });
  const [loadbtn, setloadbtn] = useState(false);
  const [convBtn, setconvBtn] = useState(true);

  const [FromGlobal, setFromGlobal] = useState("USDT");
  const [ToGlobal, setToGlobal] = useState("EGOLD (V2)");
  const [Fswapamnt, setFswapamnt] = useState(null);
  const [Tswapamnt, setTswapamnt] = useState(null);
  const [fromTokenBalance, setfromTokenBalance] = useState(0.0);
  const [toTokenBalance, settoTokenBalance] = useState(0);

  const [rangevalue, setrangeValue] = useState(1.5);
  const [keyval, setkeyval] = useState(0);
  const [Tkeyval, setTkeyval] = useState(0);

  const [EGperBUSD, setEGperBUSD] = useState("0.00000");

  const datatok = [
    {
      id: 1,
      label: "BUSD",
      icon: "busdicon",
      tokencontract: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    },
    {
      id: 2,
      label: "EGOLD (V1)",
      icon: "egoldicon",
      tokencontract: "0x8005D97E993668a528008d16338B42f9e976ED0F",
    },
    {
      id: 3,
      label: "EGOLD (V2)",
      icon: "egoldicon",
      tokencontract: "0xBE76F927d274072266caDe09Daa54750cd4293a1",
    },
    {
      id: 4,
      label: "USDT",
      icon: "usdticon",
      tokencontract: "0x55d398326f99059fF775485246999027B3197955",
    },
  ];

  const getPer = async (fromcontract, tocontract) => {
    const Web3 = require("web3");
    const web3 = new Web3(process.env.REACT_APP_RPC);

    const pancakeRouterInstance = new web3.eth.Contract(
      dataVal.pcrouterabi,
      process.env.REACT_APP_PANCAKE_ROUTER_ADDR
    );
    var oneToken = web3.utils.toWei("1", "ether");

    await pancakeRouterInstance.methods
      .getAmountsOut(oneToken, [fromcontract, tocontract])
      .call()
      .then((res) => {
        setEGperBUSD(parseFloat(web3.utils.fromWei(res[1])));
      });
  };

  useEffect(() => {
    if (isActive) {
      console.log("active");
      setFromGlobal("USDT");
      setToGlobal("EGOLD (V2)");
      getSummary(
        datatok.find((item) => item.label == "USDT").tokencontract,
        datatok.find((item) => item.label == "EGOLD (V2)").tokencontract
      );
    } else {
      setrangeValue(1);
      setFromGlobal("USDT");
      setToGlobal("EGOLD (V2)");
      setFswapamnt(null);
      setTswapamnt(null);
      setfromTokenBalance(0.0);
      settoTokenBalance(0);
      getSummary(
        datatok.find((item) => item.label == "USDT").tokencontract,
        datatok.find((item) => item.label == "EGOLD (V2)").tokencontract
      );
    }
  }, [account]);

  useEffect(() => {
    setFromGlobal("USDT");
    setToGlobal("EGOLD (V2)");
    getSummary(
      datatok.find((item) => item.label == "USDT").tokencontract,
      datatok.find((item) => item.label == "EGOLD (V2)").tokencontract
    );
    getPer(
      datatok.find((item) => item.label == "EGOLD (V2)").tokencontract,
      datatok.find((item) => item.label == "USDT").tokencontract
    );
  }, []);

  useEffect(() => {
    refreshState();
  }, [FromGlobal, ToGlobal]);

  useEffect(() => {
    getOnchangefrom(Fswapamnt);
  }, [FromGlobal]);

  useEffect(() => {
    getOnchangeto(Tswapamnt);
  }, [ToGlobal]);

  useEffect(() => {
    if (props.price) {
      if (parseInt(props.price) > 2000) {
        var uprate = parseFloat(props.price) + parseFloat(props.price) * 0.002;
      } else {
        var uprate = parseFloat(props.price) + parseFloat(props.price) * 0.007;
      }
      setDefaultT(uprate);
    }
  }, [props.price, FromGlobal]);

  const getSummary = async (fromcontract, tocontract) => {
    if (FromGlobal !== "BNB") {
      var fromtokencontractInstance = await new web3.eth.Contract(
        dataVal.tokenabi,
        fromcontract
      );
    }
    if (ToGlobal !== "BNB") {
      var totokencontractInstance = await new web3.eth.Contract(
        dataVal.tokenabi,
        tocontract
      );
    }

    if (localStorage.getItem("acct") && account) {
      if (FromGlobal == "BNB") {
        var xbal = await web3.eth.getBalance(localStorage.getItem("acct"));
        setfromTokenBalance(noround(web3.utils.fromWei(xbal), 5));
      } else {
        await fromtokencontractInstance.methods
          .balanceOf(account)
          .call()
          .then((res) => {
            setfromTokenBalance(noround(web3.utils.fromWei(res), 3));
          });
      }
      if (ToGlobal == "BNB") {
        var xbal = await web3.eth.getBalance(localStorage.getItem("acct"));
        settoTokenBalance(noround(web3.utils.fromWei(xbal), 5));
      } else {
        await totokencontractInstance.methods
          .balanceOf(account)
          .call()
          .then((res) => {
            settoTokenBalance(noround(web3.utils.fromWei(res), 3));
          });
      }
    }
  };

  const refreshState = async () => {
    setprocessState({ state: "...", data: null });
    if (FromGlobal !== null)
      var fromcontract = datatok.find(
        (item) => item.label == FromGlobal
      ).tokencontract;
    if (ToGlobal !== null)
      var tocontract = datatok.find(
        (item) => item.label == ToGlobal
      ).tokencontract;

    if (FromGlobal !== "BNB" && FromGlobal !== null) {
      var fromtokencontractInstance = await new web3.eth.Contract(
        dataVal.tokenabi,
        fromcontract
      );
    }
    if (ToGlobal !== "BNB" && ToGlobal !== null) {
      var totokencontractInstance = await new web3.eth.Contract(
        dataVal.tokenabi,
        tocontract
      );
    }

    //these conditions are set to differentiate between bnb balance and other bep20 token balance
    if (localStorage.getItem("acct") && account) {
      if (FromGlobal !== null) {
        if (FromGlobal == "BNB") {
          var xbal = await web3.eth.getBalance(localStorage.getItem("acct"));
          setfromTokenBalance(noround(web3.utils.fromWei(xbal), 5));
        } else {
          await fromtokencontractInstance.methods
            .balanceOf(account)
            .call()
            .then((res) => {
              setfromTokenBalance(noround(web3.utils.fromWei(res), 3));
            });
        }
      } else {
        setFswapamnt("");
        setfromTokenBalance(0.0);
      }
      if (ToGlobal !== null) {
        if (ToGlobal == "BNB") {
          var xbal = await web3.eth.getBalance(localStorage.getItem("acct"));
          settoTokenBalance(noround(web3.utils.fromWei(xbal), 5));
        } else {
          await totokencontractInstance.methods
            .balanceOf(account)
            .call()
            .then((res) => {
              settoTokenBalance(noround(web3.utils.fromWei(res), 3));
            });
        }
      } else {
        setTswapamnt("");
        settoTokenBalance(0.0);
      }
    }
  };

  //to handle From input onchange
  const getOnchangefrom = async (value) => {
    setFswapamnt(value);
    if (value > 0) {
      if (
        (FromGlobal == "BNB" && ToGlobal == "WBNB") ||
        (FromGlobal == "WBNB" && ToGlobal == "BNB") ||
        (FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V2)") ||
        (FromGlobal == "EGOLD (V2)" && ToGlobal == "EGOLD (V1)") ||
        (FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V1)") ||
        (FromGlobal == "EGOLD (V2)" && ToGlobal == "EGOLD (V2)")
      ) {
        setTswapamnt(value);
      } else {
        const swapTokenval = new web3.eth.Contract(
          dataVal.pcrouterabi,
          process.env.REACT_APP_PANCAKE_ROUTER_ADDR
        );
        var weiToken = web3.utils.toWei(value.toString(), "ether");
        await swapTokenval.methods
          .getAmountsOut(weiToken, [
            datatok.find((item) => item.label == FromGlobal).tokencontract,
            datatok.find((item) => item.label == ToGlobal).tokencontract,
          ])
          .call()
          .then((res) => {
            setTswapamnt(parseFloat(web3.utils.fromWei(res[1])).toFixed(4));
          });
      }
    } else {
      setTswapamnt(0.0);
    }
  };

  //to handle To input onchange
  const getOnchangeto = async (value) => {
    setTswapamnt(value);
    if (value > 0) {
      if (
        (FromGlobal == "BNB" && ToGlobal == "WBNB") ||
        (FromGlobal == "WBNB" && ToGlobal == "BNB") ||
        (FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V2)") ||
        (FromGlobal == "EGOLD (V2)" && ToGlobal == "EGOLD (V1)") ||
        (FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V1)") ||
        (FromGlobal == "EGOLD (V2)" && ToGlobal == "EGOLD (V2)")
      ) {
        setFswapamnt(value);
      } else {
        const swapTokenval = new web3.eth.Contract(
          dataVal.pcrouterabi,
          process.env.REACT_APP_PANCAKE_ROUTER_ADDR
        );
        var weiToken = web3.utils.toWei(value.toString(), "ether");
        await swapTokenval.methods
          .getAmountsIn(weiToken, [
            datatok.find((item) => item.label == FromGlobal).tokencontract,
            datatok.find((item) => item.label == ToGlobal).tokencontract,
          ])
          .call(async function (err, res) {
            if (err) {
              console.log("An error occured", err);
              return;
            } else {
              console.log("xixi-", res);
              setFswapamnt(parseFloat(web3.utils.fromWei(res[0])).toFixed(2));
            }
          });
      }
    } else {
      setFswapamnt(0.0);
    }
  };

  async function approveToken() {
    try {
      if (localStorage.getItem("acct")) {
        setprocessState({ state: "...", data: null });
        setloadbtn(true);
        const webb3 = new Web3(connector.provider);
        setconvBtn(true);
        const tokencontractInstance = await new webb3.eth.Contract(
          dataVal.tokenabi,
          datatok.find((item) => item.label == FromGlobal).tokencontract
        );

        const estimatedGas = await tokencontractInstance.methods
          .approve(
            process.env.REACT_APP_PANCAKE_ROUTER_ADDR,
            webb3.utils.toWei(Fswapamnt.toString(), "ether")
          )
          .estimateGas({ from: account });

        await tokencontractInstance.methods
          .approve(
            process.env.REACT_APP_PANCAKE_ROUTER_ADDR,
            webb3.utils.toWei(Fswapamnt.toString(), "ether")
          )
          .send({
            from: account,
            gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
            gasPrice: await web3.eth.getGasPrice(),
          })
          .on("receipt", async function (res) {
            setloadbtn(false);
            setconvBtn(false);
          })
          .on("error", function (error, receipt) {
            setloadbtn(false);
            setconvBtn(true);
            console.log("error", error);
            checkerror(error);
          });
      }
    } catch (e) {
      console.log(e);
      setloadbtn(false);
      checkerror(e);
    }
  }

  //To swap one token to another
  const swapToken = async (e) => {
    try {
      if (localStorage.getItem("acct")) {
        setprocessState({ state: "...", data: null });
        if (Fswapamnt > 0) {
          setprocessState({ state: "processing", data: null });
          const web3 = new Web3(connector.provider);
          const swapTokenInstance = new web3.eth.Contract(
            dataVal.pcrouterabi,
            process.env.REACT_APP_PANCAKE_ROUTER_ADDR
          );
          var today = new Date();
          var futureDate = new Date(today.getTime() + 60000);
          var Famount = String(Fswapamnt);

          var Tamount = parseFloat(((100 - rangevalue) / 100) * Tswapamnt)
            .toFixed(16)
            .toString();
          if (FromGlobal == "BNB" && ToGlobal == "WBNB") {
            convertbnbtowbnb();
          } else if (FromGlobal == "WBNB" && ToGlobal == "BNB") {
            convertwbnbtobnb();
          } else if (FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V2)") {
            handleEGConvert(1);
          } else if (FromGlobal == "EGOLD (V2)" && ToGlobal == "EGOLD (V1)") {
            handleEGConvert(2);
          } else {
            if (FromGlobal == "BNB") {
              // can't use estimate gas cause the contract method won't work without value(bnb) send along with contract execution

              var method = swapTokenInstance.methods
                .swapExactETHForTokens(
                  web3.utils.toWei(Tamount.toString(), "ether"),
                  [
                    datatok.find((item) => item.label == FromGlobal)
                      .tokencontract,
                    datatok.find((item) => item.label == ToGlobal)
                      .tokencontract,
                  ],
                  account,
                  futureDate.getTime()
                )
                .send({
                  from: account,
                  gasPrice: await web3.eth.getGasPrice(),
                  value: web3.utils.toWei(Famount.toString(), "ether"),
                });
            } else if (ToGlobal == "BNB") {
              const estimatedGas = await swapTokenInstance.methods
                .swapExactTokensForETH(
                  web3.utils.toWei(Famount.toString(), "ether"),
                  web3.utils.toWei(Tamount.toString(), "ether"),
                  [
                    datatok.find((item) => item.label == FromGlobal)
                      .tokencontract,
                    datatok.find((item) => item.label == ToGlobal)
                      .tokencontract,
                  ],
                  account,
                  futureDate.getTime()
                )
                .estimateGas({ from: account });

              var method = swapTokenInstance.methods
                .swapExactTokensForETH(
                  web3.utils.toWei(Famount.toString(), "ether"),
                  web3.utils.toWei(Tamount.toString(), "ether"),
                  [
                    datatok.find((item) => item.label == FromGlobal)
                      .tokencontract,
                    datatok.find((item) => item.label == ToGlobal)
                      .tokencontract,
                  ],
                  account,
                  futureDate.getTime()
                )
                .send({
                  from: account,
                  gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
                  gasPrice: await web3.eth.getGasPrice(),
                });
            } else {
              const estimatedGas = await swapTokenInstance.methods
                .swapExactTokensForTokens(
                  web3.utils.toWei(Famount.toString(), "ether"),
                  web3.utils.toWei(Tamount.toString(), "ether"),
                  [
                    datatok.find((item) => item.label == FromGlobal)
                      .tokencontract,
                    datatok.find((item) => item.label == ToGlobal)
                      .tokencontract,
                  ],
                  account,
                  futureDate.getTime()
                )
                .estimateGas({ from: account });

              var method = swapTokenInstance.methods
                .swapExactTokensForTokens(
                  web3.utils.toWei(Famount.toString(), "ether"),
                  web3.utils.toWei(Tamount.toString(), "ether"),
                  [
                    datatok.find((item) => item.label == FromGlobal)
                      .tokencontract,
                    datatok.find((item) => item.label == ToGlobal)
                      .tokencontract,
                  ],
                  account,
                  futureDate.getTime()
                )
                .send({
                  from: account,
                  gasLimit: estimatedGas + parseInt(estimatedGas * 0.1),
                  gasPrice: await web3.eth.getGasPrice(),
                });
            }
            await method
              .on("receipt", async function (cres) {
                setprocessState({ state: "done", data: cres.transactionHash });
                setconvBtn(true);
                getSummary(
                  datatok.find((item) => item.label == FromGlobal)
                    .tokencontract,
                  datatok.find((item) => item.label == ToGlobal).tokencontract
                );
                console.log("SWAPP SUCEESS");
              })
              .on("error", async function (e) {
                checkerror(e);
              });
          }
        } else {
          setprocessState({
            state: "error",
            data: "Please provide a valid input",
          });
        }
      }
    } catch (e) {
      console.log(e);
      checkerror(e);
    }
  };

  function checkerror(err) {
    if (
      err.message ==
        "Please pass numbers as strings or BN objects to avoid precision errors." ||
      err.message.includes(
        "while converting number to string, invalid number value "
      )
    ) {
      setprocessState({
        state: "error",
        data: "Please provide a valid input",
      });
    } else if (JSON.stringify(err.message).includes("transaction underpriced"))
      setprocessState({
        state: "error",
        data: "Transaction was underpriced. Please try increasing the gas price",
      });
    else
      setprocessState({
        state: "error",
        data: JSON.stringify(err.message),
      });
  }

  function numFormatter(num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(1) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
      return (num / 1000000).toFixed(1) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      var x = noround(num, 2);
      return x; // if value < 1000, nothing to do
    }
  }
  function noround(number, decimalDigits) {
    const powerOfTen = Math.pow(10, decimalDigits);
    const formattedNumber = Math.floor(number * powerOfTen) / powerOfTen;
    if (Number.isInteger(formattedNumber)) {
      return roundzeroes(formattedNumber, 2);
    } else return formattedNumber;
  }

  //to round decimal points with zeroes
  function roundzeroes(val, x) {
    var float = parseFloat(val).toFixed(18);
    var num = float.toString();
    var n = num.slice(0, num.indexOf(".") + (x + 1));
    return n;
  }

  //To get first 3 non-zero digits(eg: 0.00000000123455 will return 0.00000000123)
  function getnonZero(num) {
    if (parseFloat(num) < 1 && parseFloat(num) > 0) {
      let numString = num.toString();
      numString = numString.replace(/^0+/, "");
      let nonZeroIndex = numString.search(/[1-9]/);
      if (nonZeroIndex === -1) {
        return "0";
      }

      let end = Math.min(nonZeroIndex + 4, numString.length);

      return "0" + numString.slice(0, end);
    } else {
      return noround(num, 3);
    }
  }

  function changeView(type) {
    if (type == "v1") {
      setFromGlobal("BUSD");
      setToGlobal("EGOLD (V1)");
      getSummary(
        datatok.find((item) => item.label == "BUSD").tokencontract,
        datatok.find((item) => item.label == "EGOLD (V1)").tokencontract
      );
      getPer(
        datatok.find((item) => item.label == "EGOLD (V1)").tokencontract,
        datatok.find((item) => item.label == "BUSD").tokencontract
      );
    } else {
      setFromGlobal("USDT");
      setToGlobal("EGOLD (V2)");
      getSummary(
        datatok.find((item) => item.label == "USDT").tokencontract,
        datatok.find((item) => item.label == "EGOLD (V2)").tokencontract
      );
      getPer(
        datatok.find((item) => item.label == "EGOLD (V2)").tokencontract,
        datatok.find((item) => item.label == "USDT").tokencontract
      );
    }
  }

  async function setMax(value) {
    setFswapamnt(value);
    if (value > 0) {
      if (
        (FromGlobal == "BNB" && ToGlobal == "WBNB") ||
        (FromGlobal == "WBNB" && ToGlobal == "BNB") ||
        (FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V2)") ||
        (FromGlobal == "EGOLD (V2)" && ToGlobal == "EGOLD (V1)") ||
        (FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V1)") ||
        (FromGlobal == "EGOLD (V2)" && ToGlobal == "EGOLD (V2)")
      ) {
        setTswapamnt(value);
      } else {
        const swapTokenval = new web3.eth.Contract(
          dataVal.pcrouterabi,
          process.env.REACT_APP_PANCAKE_ROUTER_ADDR
        );
        var weiToken = web3.utils.toWei(value.toString(), "ether");
        await swapTokenval.methods
          .getAmountsOut(weiToken, [
            datatok.find((item) => item.label == FromGlobal).tokencontract,
            datatok.find((item) => item.label == ToGlobal).tokencontract,
          ])
          .call()
          .then((res) => {
            setTswapamnt(parseFloat(web3.utils.fromWei(res[1])).toFixed(4));
          });
      }
    } else {
      setTswapamnt(0.0);
    }
  }

  async function setDefaultT(value) {
    setFswapamnt(value);
    if (value > 0) {
      if (
        (FromGlobal == "BNB" && ToGlobal == "WBNB") ||
        (FromGlobal == "WBNB" && ToGlobal == "BNB") ||
        (FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V2)") ||
        (FromGlobal == "EGOLD (V2)" && ToGlobal == "EGOLD (V1)") ||
        (FromGlobal == "EGOLD (V1)" && ToGlobal == "EGOLD (V1)") ||
        (FromGlobal == "EGOLD (V2)" && ToGlobal == "EGOLD (V2)")
      ) {
        setTswapamnt(value);
      } else {
        const swapTokenval = new web3.eth.Contract(
          dataVal.pcrouterabi,
          process.env.REACT_APP_PANCAKE_ROUTER_ADDR
        );
        var weiToken = web3.utils.toWei(value.toString(), "ether");
        await swapTokenval.methods
          .getAmountsIn(weiToken, [
            datatok.find((item) => item.label == ToGlobal).tokencontract,
            datatok.find((item) => item.label == FromGlobal).tokencontract,
          ])
          .call()
          .then((res) => {
            setTswapamnt(parseFloat(web3.utils.fromWei(res[0])).toFixed(4));
          });
      }
    } else {
      setTswapamnt(0.0);
    }
  }

  return (
    <>
      <div class="walletamtsec mb20">
        <div
          class="lightgrey-bg secpadding brrr"
          style={{ paddingBottom: "30px" }}
        >
          <div class="row minertabsec" style={{ marginBottom: "14px" }}>
            <ul
              class="nav nav-pills mb-3 p-2 pb-0"
              id="pills-tab"
              role="tablist"
            >
              <li class="nav-item" role="presentation" style={{ width: "50%" }}>
                <button
                  class="nav-link typetabbtn  brltb active "
                  id="pills-gseries-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-gseries"
                  type="button"
                  role="tab"
                  aria-controls="pills-gseries"
                  aria-selected="false"
                  onClick={() => {
                    changeView("v2");
                  }}
                >
                  Buy EGOLD (V2)
                </button>
              </li>
              <li class="nav-item" role="presentation" style={{ width: "50%" }}>
                <button
                  class="nav-link typetabbtn brrtb"
                  id="pills-sseries-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-sseries"
                  type="button"
                  role="tab"
                  aria-controls="pills-sseries"
                  aria-selected="true"
                  onClick={() => {
                    changeView("v1");
                  }}
                >
                  Buy EGOLD (V1)
                </button>
              </li>
            </ul>
          </div>
          <p
            class="fs12"
            style={{
              padding: "12px",
              paddingTop: "0px",
              paddingBottom: "6px",
              color: "#959494",
            }}
          >
            {FromGlobal} Bal : {FromGlobal == null ? "0.0" : fromTokenBalance}{" "}
            <span style={{ float: "right" }}>
              {ToGlobal} Bal : {ToGlobal == null ? "0.0" : toTokenBalance}
            </span>
          </p>
          <div class="detailCard secpadding" style={{ marginBottom: "10px" }}>
            <div class="fromreactangle pr">
              <div class="p15">
                <p class="fs12 mb10">~ {ToGlobal} Qty</p>
                <p class="fromreactp">
                  <input
                    className="swapinput"
                    placeholder="0.00"
                    key={Tkeyval}
                    value={Tswapamnt}
                    onChange={(e) => getOnchangeto(e.target.value)}
                  />
                  <a class="selecttokendrop">
                    <span
                      class="float-right dropwrapper fromreactspan"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "black",
                        right: 14,
                        top: 15,
                      }}
                    >
                      <span
                        className={
                          datatok.find((item) => item.label == ToGlobal).icon
                        }
                      ></span>
                      {ToGlobal}{" "}
                    </span>
                  </a>
                  {/* <span
                    class="float-right yellowtext fs14 pricebadge"
                    style={{
                      cursor: "pointer",
                      marginRight: 6
                    }}
                    onClick={(e) => setT(Math.ceil(parseFloat(props.price)/EGperBUSD))}
                  >
                   {Math.ceil(parseFloat(props.price)/EGperBUSD)}.00
                  </span> */}
                  <span
                    class="float-right yellowtext fs14"
                    style={{
                      cursor: "pointer",
                      marginTop: "6px",
                    }}
                    onClick={(e) => setMax(fromTokenBalance)}
                  >
                    Use Max
                  </span>
                </p>
              </div>
            </div>
          </div>
          <p class="fs12 ratestyle">
            Rate :<br className="brmob" /> 1 EGOLD (V1) ={" "}
            {getnonZero(EGperBUSD)} {FromGlobal}{" "}
            <span className="convertusdttot">
              Total :<br className="brmob" />
              <span className="convertusdttotspan2">
                {" "}
                {Fswapamnt} {FromGlobal}
              </span>
            </span>
          </p>
          <div style={{ display: "flex", gap: "4%", marginTop: "10px" }}>
            <a
              className={`btn-color-primary ${!convBtn && "disabled"}`}
              style={{ cursor: "pointer", width: "48%" }}
              onClick={approveToken}
            >
              {loadbtn ? <Spinner size="sm" /> : "Approve"}
            </a>
            <a
              className={`btn-color-primary ${convBtn && "disabled"}`}
              style={{ cursor: "pointer", width: "48%" }}
              onClick={swapToken}
            >
              Buy
            </a>
          </div>
          {processState.state == "..." ? (
            ""
          ) : processState.state == "processing" ? (
            <>
              <br />
              <LoaderCard />
            </>
          ) : processState.state == "done" ? (
            <>
              <br />
              <ConfirmCard tx={processState.data} />
            </>
          ) : (
            <>
              <br />
              <ErrorCard err={processState.data} />
            </>
          )}
        </div>
      </div>

      <br />
    </>
  );
}
